import { ValidatorFn } from '@angular/forms';

export const equalsValidator = (...controlNames: string[]): ValidatorFn => {
    return (control) => {
        const firstControl = control.get(controlNames[0]);
        const allEqual = controlNames.every(name => control.get(name)?.value === firstControl?.value);

        if (!allEqual) {
            controlNames.forEach(name => {
                const c = control.get(name);
                c?.setErrors({ ...c?.errors, notEqual: true });
            });
        } else {
            controlNames.forEach(name => {
                const c = control.get(name);
                delete c?.errors?.notEqual;
                if (Object.keys(c?.errors || {}).length === 0) {
                    c?.setErrors(null);
                }
            });
        }

        return null;
    }
};
